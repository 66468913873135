import React from 'react'
import styled, { css } from 'styled-components'
import {
  TwitterShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  EmailShareButton,
  WhatsappShareButton,
} from 'react-share'

// Elements
import ParseContent from 'components/shared/ParseContent'
import BlockDefault from 'components/elements/Blocks/BlockDefault'
import NavLink from 'components/shared/NavLink'
import FlexContentHandler from 'components/shared/FlexContentHandler'

// Images
import Linkedin from 'img/linkedin.inline.svg'
import Facebook from 'img/facebook.inline.svg'
import Twitter from 'img/twitter.inline.svg'
import Email from 'img/email.inline.svg'
import Whatsapp from 'img/whatsapp.inline.svg'
import ArrowLeft from 'img/arrowleft.inline.svg'
import listitem from 'img/listitem.svg'

const Wrapper = styled.div<{ hasShareButtons: boolean }>`
  ${(props) =>
    props.hasShareButtons &&
    css`
      margin-bottom: 10rem;
    `}

  @media (min-width: 992px) {
    margin-top: 2rem;
  }
  @media (max-width: 991px) {
    margin-top: 3rem !important;
  }
`

const Content = styled(ParseContent)<{ postType: string }>`
  & h1,
  & h2,
  & h4,
  & h5,
  & h6 {
    ${(props) =>
      props.postType === 'inquiry'
        ? css`
            font-size: 25px !important;
            font-family: ${({ theme }) => theme.font.family.primary} !important;
            & strong {
              font-weight: 700 !important;
            }
          `
        : css`
            font-size: 25px;
            line-height: 30px;
            @media (max-width: 991px) {
              font-size: 30px !important;
            }
          `}
  }
  & h3 {
    font-size: 30px;
    @media (max-width: 991px) {
      font-size: 20px !important;
    }
  }

  & p,
  & span {
    font-weight: ${({ theme }) => theme.font.weight.regular};
  }

  & ul li {
    list-style: none;
    position: relative;
    line-height: 30px;

    &:before {
      content: '';
      background-image: url(${listitem});
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      top: 5px;
      left: -33px;
      z-index: 99;
      width: 17px;
      height: 17px;
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  & blockquote {
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      @media (min-width: 992px) {
        font-size: 38px !important;
      }
    }
  }
`

const StyledBlockDefault = styled(BlockDefault)<{
  bgColor: string
  hasPadding: boolean
}>`
  ${(props) =>
    props.bgColor === 'white'
      ? css`
          background-color: #ffffff;
        `
      : css`
          background-color: ${props.theme.color.primary};
        `};
  ${(props) =>
    props.hasPadding
      ? css`
          padding-top: 5rem;
        `
      : css`
          padding-top: 0;
        `};
`

const CtaWrapper = styled.div`
  position: relative;
  z-index: 2;
  margin-top: -3rem;
`

interface TextInBlockProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
  location: any
}

const TextInBlock: React.FC<TextInBlockProps> = ({ fields, location }) => {
  const bgColor = fields.backgroundcolor

  return (
    <Wrapper hasShareButtons={fields.sharebuttons || false}>
      <div className="container px-0">
        <div className="row justify-content-center">
          <div className={`col-lg-${fields.blockwidth}`}>
            <StyledBlockDefault
              hasPadding={fields.sharebuttons || false}
              bgColor={bgColor || 'white'}
              noBorder
              noHoverEffect
              className="row flex-row p-lg-5 align-items-start"
            >
              {fields.title && (
                <div className={`col-lg-${12 - (fields.columnwidth || 0)}`}>
                  <Content
                    content={fields.title}
                    className="py-5"
                    postType={fields.posttype || 'default'}
                  />
                </div>
              )}

              {fields.description && (
                <div className={`col-lg-${fields.columnwidth}`}>
                  <Content
                    content={fields.description}
                    postType={fields.posttype || 'default'}
                    className="py-lg-5 py-4"
                  />
                </div>
              )}

              {fields.posttype === 'inquiry' && (
                <FlexContentHandler
                  prefix="Fragment_FlexFragment"
                  fields={fields?.secondelement?.flex_fragment}
                  location={location}
                />
              )}

              {fields.sharebuttons && (
                <div>
                  <ShareButtons location={location} />
                </div>
              )}

              {fields.linkbelow && (
                <div className="mt-5">
                  <ArrowLeft />
                  <NavLink to={fields.linkbelow.url || '#'}>
                    <span className="ps-3">{fields.linkbelow.title}</span>
                  </NavLink>
                </div>
              )}
            </StyledBlockDefault>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const ShareWrapper = styled.div`
  & svg {
    width: 34px;
    height: 34px;
    margin-right: 1rem;
    opacity: 0.85;

    &:hover {
      opacity: 1;
    }
  }
`

interface ShareButtonProps {
  location: any
}

const ShareButtons: React.FC<ShareButtonProps> = ({ location }) => {
  const shareUrl = location ? location.href : ''
  return (
    <ShareWrapper>
      <p>Delen</p>
      <LinkedinShareButton url={shareUrl} title="Bericht van Borg-OSC">
        <Linkedin />
      </LinkedinShareButton>
      <FacebookShareButton url={shareUrl} title="Bericht van Borg-OSC">
        <Facebook />
      </FacebookShareButton>
      <TwitterShareButton url={shareUrl} title="Bericht van Borg-OSC">
        <Twitter />
      </TwitterShareButton>
      <EmailShareButton url={shareUrl} title="Bericht van Borg-OSC">
        <Email />
      </EmailShareButton>
      <WhatsappShareButton url={shareUrl} title="Bericht van Borg-OSC">
        <Whatsapp />
      </WhatsappShareButton>
    </ShareWrapper>
  )
}

export default TextInBlock

import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'

const Row = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.secondaryDark};
  &:last-child {
    border-bottom: unset;
  }
`

interface TextWithBorderProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const TextWithBorder: React.FC<TextWithBorderProps> = ({ fields }) => (
  <section className="my-5 pt-5">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-11">
          {fields.seperatedrows?.map((row, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Row className="row py-5" key={index}>
              <div className="col-lg-5">
                <ParseContent content={row?.title} />
              </div>
              <div className="col-lg-7">
                <ParseContent content={row?.description} className="ps-lg-5" />
              </div>
            </Row>
          ))}
        </div>
      </div>
    </div>
  </section>
)

export default TextWithBorder

import React from 'react'

// Components
import TextDefault from 'components/flex/Text/TextDefault'
import TextWithBlocks from 'components/flex/Text/TextWithBlocks'
import TextWithQuoteAndImage from 'components/flex/Text/TextWithQuoteAndImage'
import TextWithLinks from 'components/flex/Text/TextWithLinks'
import TextWithImage from 'components/flex/Text/TextWithImage'
import TextWithBorder from 'components/flex/Text/TextWithBorder'
import TextInBlock from 'components/flex/Text/TextInBlock'
import TextWithLinksCurved from 'components/flex/Text/TextWithLinksCurved'

interface TextShellProps {
  // eslint-disable-next-line camelcase
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Text
  location?: any
}

interface TextsProps {
  [key: string]: any
}

const TextShell: React.FC<TextShellProps> = ({ fields, location = {} }) => {
  const texts: TextsProps = {
    default: TextDefault,
    withblocks: TextWithBlocks,
    withquoteandimage: TextWithQuoteAndImage,
    withlinks: TextWithLinks,
    withlinkscurved: TextWithLinksCurved,
    withimage: TextWithImage,
    withborder: TextWithBorder,
    inblock: TextInBlock,
  }

  if (!fields.styletype || !texts[fields.styletype]) {
    return null
  }

  const Component = texts[fields.styletype]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
    />
  )
}

export default TextShell

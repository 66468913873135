import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import { useMedia } from 'use-media'

// Elements
import ParseContent from 'components/shared/ParseContent'
import BlockDefault from 'components/elements/Blocks/BlockDefault'
import NavLink from 'components/shared/NavLink'

// Images
import ArrowRight from 'img/arrowright.inline.svg'

const StyledNavLink = styled(NavLink)`
  position: relative;
  font-family: ${({ theme }) => theme.font.family.secondary};
  font-weight: ${({ theme }) => theme.font.weight.bold} !important;
  font-size: ${({ theme }) => theme.font.size.huge};
  transition: all 0.3s ease-in-out;
  width: 100%;
  &:hover {
    /* color: ${({ theme }) => theme.color.secondary}; */
  }
  &:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 1px;
    border-bottom: 1px solid #e5e5e5;

    @media (min-width: 1400px) {
      width: 80%;
    }
    @media (max-width: 1399px) {
      width: 100%;
    }
  }

  & span {
    @media (max-width: 575px) {
      font-size: 20px;
    }
  }

  & a > :last-child {
    border-bottom: unset;
  }
`

const LinksWrapper = styled.div`
  & ul {
    & li {
      list-style: none;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        height: 10px;
        width: 10px;
        ${({ theme }) => css`
          background: ${theme.color.gradient.to};
          background: ${`rgb(${theme.color.gradient.to})`};
          background: ${`linear-gradient(90deg, rgba(${theme.color.gradient.from}, 1) 0%, rgba(${theme.color.gradient.to}, 1) 100%);`};
        `};
        border-radius: 50%;
        @media (min-width: 576px) {
          top: 28px;
        }
        @media (max-width: 575px) {
          top: 26px;
        }
      }
    }
    &:last-child > a {
      border: 0 !important;
    }
    @media (max-width: 1199px) {
      padding-left: 0;
    }
  }
  @media (min-width: 1400px) {
    width: 85%;
  }
  @media (max-width: 1399px) {
    width: 100%;
  }
`

const StyledBlockDefault = styled(BlockDefault)`
  &:after {
    content: '';
    position: absolute;
    left: 100%;
    top: 0px;
    bottom: 0px;
    width: 2000px;
    border-top: 3px solid ${({ theme }) => theme.color.secondary};
    border-bottom: 3px solid ${({ theme }) => theme.color.secondary};
    background: ${({ theme }) => theme.color.light};
    margin-top: -0.18rem;
    margin-bottom: -0.2rem;
  }
`

const Content = styled(ParseContent)`
  & p {
    /* text-align: justify; */
  }
`

const BulletLink = styled.li`
  &:last-child {
    & > a {
      &:before {
        border-bottom: unset !important;
      }
    }
  }
`

const ArrowLink = styled.div`
  &:last-child {
    & > a {
      &:before {
        border-bottom: unset !important;
      }
    }
  }
`

interface TextWithLinksProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const TextWithLinks: React.FC<TextWithLinksProps> = ({ fields }) => (
  <section className="py-5 mb-5">
    <div className="container position-relative">
      <div className="row justify-content-center">
        <div className="col-lg-12">
          <div className="row">
            <div className={`col-xl-${fields.columnwidth} col-lg-6`}>
              <Content content={fields.description} className="pe-sm-5" />
            </div>
            <div
              className={`col-xl-${
                12 - (fields.columnwidth || 0)
              } col-lg-6 mt-5 mt-xl-0`}
            >
              <StyledBlockDefault
                noShadow
                noBorderRight
                className="align-items-start position-relative"
              >
                <LinksWrapper className="d-flex flex-column p-lg-4 ms-xl-5">
                  {fields.linkstype === 'arrows' ? (
                    <LinksWithArrows fields={fields} />
                  ) : (
                    <LinksWithBullets fields={fields} />
                  )}
                </LinksWrapper>
              </StyledBlockDefault>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

const LinksWithBullets: React.FC<TextWithLinksProps> = ({ fields }) => (
  <>
    <ul className="mb-0">
      {fields?.links?.map((link, index: number) => (
        <BulletLink>
          <StyledNavLink
            to={link?.link?.url || '/'}
            className="ps-3 px-2 py-4 d-flex justify-content-between align-items-center "
            // eslint-disable-next-line react/no-array-index-key
            key={index}
          >
            <span className="text-start">{link?.link?.title}</span>
          </StyledNavLink>
        </BulletLink>
      ))}
    </ul>
  </>
)

const LinksWithArrows: React.FC<TextWithLinksProps> = ({ fields }) => {
  const isSmallDevice = useMedia({ maxWidth: 1199 })
  const [isHover, setHover] = useState<number | null>(null)

  return (
    <>
      {fields?.links?.map((link, index: number) => {
        const hovered: boolean = index === isHover

        return (
          <ArrowLink
            onMouseEnter={() => setHover(index)}
            onMouseLeave={() => setHover(null)}
          >
            <StyledNavLink
              to={link?.link?.url || '/'}
              className="px-2 py-2 d-flex justify-content-between align-items-center"
              // eslint-disable-next-line react/no-array-index-key
              key={index}
            >
              <span>{link?.link?.title}</span>
              <motion.div
                className="ms-sm-5"
                animate={
                  hovered
                    ? { x: isSmallDevice ? 0 : -144 }
                    : { x: isSmallDevice ? 0 : -164 }
                }
                transition={{ type: 'spring', stiffness: 100 }}
              >
                <ArrowRight />
              </motion.div>
            </StyledNavLink>
          </ArrowLink>
        )
      })}
    </>
  )
}

export default TextWithLinks

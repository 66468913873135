import React from 'react'
import styled, { css } from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

const CircleWrapper = styled.div<{ imagePosition: string }>`
  ${({ theme }) => css`
    background: ${theme.color.gradient.to};
    background: ${`rgb(${theme.color.gradient.to})`};
    background: ${`linear-gradient(90deg, rgba(${theme.color.gradient.from}, 1) 0%, rgba(${theme.color.gradient.to}, 1) 100%);`};
  `};
  @media (min-width: 992px) {
    width: 206px;
    height: 206px;
    bottom: -50px;
    right: 0;
    ${(props) =>
      props.imagePosition === 'left'
        ? css`
            right: 40px;
          `
        : css`
            bottom: -50px;
          `}
  }
  @media (max-width: 991px) {
    width: 206px;
    height: 206px;
    ${(props) =>
      props.imagePosition === 'left'
        ? css`
            right: 40px;
          `
        : css`
            bottom: -100px;
            right: -60px;
          `}
  }
  @media (max-width: 767px) {
    width: 161px;
    height: 161px;
    ${(props) =>
      props.imagePosition === 'left'
        ? css`
            bottom: -60px;
            right: 20px;
          `
        : css`
            bottom: -110px;
            right: 20px;
          `}
  }
  @media (max-width: 575px) {
    bottom: -120px;
    right: -75px;
  }
`

const CircleContent = styled(ParseContent)`
  & p {
    line-height: 24px;
    font-size: ${({ theme }) => theme.font.size.large} !important;
    @media (max-width: 767px) {
      line-height: 20px;
      font-size: ${({ theme }) => theme.font.size.medium} !important;
    }
  }
`

const ContentWrapper = styled.div<{ imagePosition: string }>`
  @media (min-width: 767px) {
    ${(props) =>
      props.imagePosition === 'left'
        ? css`
            border-left: 4px solid ${({ theme }) => theme.color.primary};
          `
        : css`
            border-right: 4px solid ${({ theme }) => theme.color.primary};
          `}
  }

  @media (max-width: 1199px) {
    margin-bottom: 3rem;
  }
`

const Content = styled(ParseContent)`
  & blockquote {
    font-size: ${({ theme }) => theme.font.size.quote};
    font-family: ${({ theme }) => theme.font.family.secondary};
    & > p {
      line-height: ${({ theme }) => theme.font.size.mega};
      @media (max-width: 1199px) {
        font-size: ${({ theme }) => theme.font.size.huge} !important;
        line-height: ${({ theme }) => theme.font.size.huge};
      }
      @media (max-width: 991px) {
        font-size: ${({ theme }) => theme.font.size.post} !important;
        line-height: ${({ theme }) => theme.font.size.huge};
      }
    }
  }
`

const ImageWrapper = styled.div`
  @media (max-width: 575px) {
    width: 60%;
    top: -100px;
  }
`

const Image = styled(Plaatjie)<{ mirror: boolean }>`
  ${(props) =>
    props.mirror &&
    css`
      transform: scaleX(-1);
    `}

  border-radius: 50%;
  @media (min-width: 1200px) {
    height: 450px;
    width: 450px;
  }
  @media (max-width: 1199px) {
    height: 350px;
    width: 350px;
  }
  @media (max-width: 991px) {
    height: 250px;
    width: 250px;
  }
  @media (max-width: 767px) {
    height: 160px;
    width: 160px;
  }
  @media (max-width: 575px) {
    width: 190px;
    height: 190px;
  }
`

interface TextWithQuoteAndImageProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const TextWithQuoteAndImage: React.FC<TextWithQuoteAndImageProps> = ({
  fields,
}) => (
  <section className="mb-5 pb-5">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-12">
          <div className="row">
            <div
              className={`col-sm-${fields.columnwidth} ${
                fields.imageposition === 'left' ? 'order-sm-2' : ''
              } d-flex align-items-center`}
            >
              <div
                className={`${
                  fields.imageposition === 'left' ? 'ps-md-5' : 'ps-md-0'
                } w-100`}
              >
                <ContentWrapper
                  imagePosition={fields.imageposition || 'left'}
                  className={`${
                    fields.imageposition === 'left' ? 'ps-md-5' : 'ps-md-0'
                  } py-5 pe-4 w-100`}
                >
                  <Content content={fields.description} className="w-100" />
                </ContentWrapper>
              </div>
            </div>
            <div
              className={`col-sm-${12 - (fields.columnwidth || 0)} ${
                fields.imageposition === 'left' ? 'order-sm-1' : ''
              }`}
            >
              <ImageWrapper className="position-relative d-flex justify-content-center justify-content-lg-start">
                <Image
                  mirror={fields.mirrorimage || false}
                  image={fields.image}
                  alt=""
                  className={`rounded-circle ${
                    fields.imageposition === 'left' ? 'ms-0' : 'ms-5'
                  }`}
                />
                <CircleWrapper
                  className="position-absolute p-3 rounded-circle d-flex align-items-center justify-content-center"
                  imagePosition={fields.imageposition || 'left'}
                >
                  <CircleContent content={fields.imagedescription} />
                </CircleWrapper>
              </ImageWrapper>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default TextWithQuoteAndImage

import React from 'react'

// Elements
import ParseContent from 'components/shared/ParseContent'

interface TextProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const Text: React.FC<TextProps> = ({ fields }) => {
  let textPosition: string

  switch (fields.textposition) {
    case 'right':
      textPosition = 'end'
      break
    case 'center':
      textPosition = 'center'
      break
    default:
      textPosition = 'start'
      break
  }

  return (
    <section className="my-5">
      <div className="container">
        <div className={`row justify-content-${textPosition}`}>
          <div className={`col-lg-${fields.columnwidth}`}>
            <ParseContent content={fields.description} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Text

import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Elements
import ParseContent from 'components/shared/ParseContent'
import ButtonPrimaryBig from 'components/elements/Buttons/ButtonPrimaryBig'
import FormLaws from 'components/flex/Form/FormLaws'

const Content = styled(ParseContent)`
  & h3,
  & h4,
  & h5,
  & h6 {
    font-size: ${({ theme }) => theme.font.size.quote} !important;
    margin-top: -20px;
    line-height: ${({ theme }) => theme.font.size.mega} !important;
  }
  @media (max-width: 991px) {
    & h2 {
      margin-top: 1rem;
      font-size: ${({ theme }) => theme.font.size.quote} !important;
      line-height: ${({ theme }) => theme.font.size.heading} !important;
    }
  }

  & p {
    margin-top: 2rem;
  }
`

const Image = styled(Plaatjie)<{ imageType: string }>`
  @media (min-width: 1400px) {
    ${(props) =>
      props.imageType === 'rounded'
        ? css`
            max-height: 500px;
            min-height: 500px;
            max-width: 500px;
            min-height: 500px;
            border-radius: 50%;
          `
        : css`
            max-height: 550px;
            min-height: 550px;
            min-width: 640px;
            min-width: 640px;
          `}
  }
  @media (max-width: 1199px) {
    max-height: 350px;
    max-width: 350px;
  }
  @media (max-width: 991px) {
    max-height: 300px;
    max-width: 300px;
    margin-bottom: 2rem;
  }
`

interface TextWithImageProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const TextWithImage: React.FC<TextWithImageProps> = ({ fields }) => {
  const [formOpen, setFormOpen] = useState(false)

  const handleFile = (e: any) => {
    e.preventDefault()
    setFormOpen(true)
  }

  return (
    <section className="my-5">
      <div className="container py-5">
        <div className="row">
          <div
            className={`col-md-${fields.columnwidth} ${
              fields.imageposition === 'left' ? 'order-2' : ''
            } d-flex flex-column justify-content-center align-items-start`}
          >
            <div className="ps-lg-5 w-100">
              <Content content={fields.description} className="w-100" />
              {fields.file && (
                <ButtonPrimaryBig
                  isCustom
                  to={fields.file.localFile?.publicURL || '/'}
                  target="_blank"
                  className="mt-5"
                >
                  <button onClick={(e) => handleFile(e)} type="button">
                    Meer info
                  </button>
                  {formOpen && (
                    <div>
                      <FormLaws
                        fileLink={fields.file.localFile?.publicURL}
                        isOpen={formOpen}
                        title="8 Wetten van Borg OSC"
                        requestClose={() => setFormOpen(false)}
                        blogFields={fields}
                      />
                    </div>
                  )}
                </ButtonPrimaryBig>
              )}
              {fields.link && (
                <ButtonPrimaryBig
                  to={fields.link.url || '/'}
                  target="_blank"
                  className="mt-5"
                >
                  {fields.link.title}
                </ButtonPrimaryBig>
              )}
            </div>
          </div>
          <div
            className={`col-md-${12 - (fields.columnwidth || 0)} ${
              fields.imageposition === 'left' ? 'order-1' : ''
            }`}
            style={
              fields.imageposition === 'left'
                ? { marginTop: '-3rem' }
                : { marginTop: '2rem' }
            }
          >
            <div className="position-relative d-flex justify-content-center">
              <Image
                imageType={fields.imagetype || 'default'}
                image={fields.image}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TextWithImage
